@import '../index.scss';

@mixin common-styles {
  .bm-MediaObject {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .bm-MediaObject__imageContainer {
    margin-right: 1rem;
  }

  .bm-MediaObject__textContainer {
    flex: 1;
    margin-right: 16px;
    flex-wrap: wrap;
    text-align: left;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 100%;
  }

  .bm-MediaObject_text {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    // width: 100%;
    white-space: normal;
  }

  .bm-MediaObject__subtext {
    color: #7e7e7e;
  }

  .bm-MediaObject__container {
    flex: 1 0;
  }

  @include media-breakpoint-down(md) {
    .bm-MediaObject.flex-wrap {
      display: block;
    }

    .bm-MediaObject__imageContainer {
      // margin-right: 1rem;
      margin-bottom: 0rem;
    }

    .bm-MediaObject.flex-wrap .bm-MediaObject__imageContainer {
      margin-right: 0rem;
      margin-bottom: 1rem;
    }

    .bm-MediaObject__container--wrap > .bm-MediaObject__imageContainer {
      margin-right: 0px;
      margin-bottom: 1rem;
    }

    .bm-MediaObject__container--wrap {
      flex-wrap: wrap !important;
    }

    .bm-MediaObject.flex-wrap .bm-MediaObject__imageContainer img {
      height: auto !important;
      width: 100% !important;
      aspect-ratio: 1 !important;
    }

    .bm-MediaObject__textContainer {
      flex-wrap: wrap;
      margin-bottom: 0rem;
    }

    .flex-wrap .bm-MediaObject__textContainer {
      flex-wrap: wrap;
      // margin-bottom: 1rem;
    }

    .bm-MediaObject__container--wrap > .bm-MediaObject__textContainer {
      flex-wrap: wrap;
      margin-bottom: 1rem;
    }
  }
}

#bm {
  @include common-styles
}

.modal {
  @include common-styles
}