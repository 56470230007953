@import '../index.scss';

@mixin common-styles {
  .bm-Icon {
    fill: $bg-dark !important;
  }

  .bm-Icon--white {
    svg path {
      fill: $white !important;
    }
  }

  .bm-Icon--dark {
    svg path {
      fill: $bg-dark !important;
    }
  }

  .bm-Icon--primary {
    svg path {
      fill: $primary !important;
    }
  }

  .bm-Icon--lightGrey {
    svg path {
      fill: rgba(255,255,255,0.25);
    }
  }

  .bm-Icon--success {
    svg path {
      fill: $success !important;
    }
  }

  .bm-Icon--danger {
    svg path {
      fill: $danger !important;
    }
  }

  .bm-Icon--secondaryButton {
    svg path {
      fill: #1E1E1E !important;
    }
  }
}

#bm {
  @include common-styles;
}

// For modals
.modal {
  @include common-styles;
}