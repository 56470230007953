@import '../index.scss';


.bm-AuthModal {
  .modal-dialog {
    min-width: 600px;
  }

  .modal-header {
    border-bottom-width: 0.25px;
    border-bottom-color: rgba(0,0,0,0.25);
    padding-top: 1.5rem;
    padding-left: 1.5rem;
    padding-right: 0.5rem;
  }

  .modal-content {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    border-radius: 30px;
  }

  .modal-footer {
    border-top-width: 0.25px;
    border-top-color: rgba(0,0,0,0.25);
    padding-bottom: 1.5rem;
  }

  .bm-AuthModal__buttonGroup {
    max-width: 60%;
  }

  .bm-AuthModal__stepper {
    width: 100%;
    max-width: 60%;
  }

  @include media-breakpoint-down(md) {
    .bm-AuthModal__buttonGroup {
      max-width: 80%;
    }

    .bm-AuthModal__stepper {
      max-width: 100%;
    }

    .modal-content {
      border-radius: 0px;
    }
  }
}

.bm-AuthModal .PhoneInput input {
  background-color: transparent !important;
  border: none !important;
  color: #fff;
  font-size: 1.5rem;
  padding-left: 0.5rem;
}

.bm-AuthModal .PhoneInput input:focus {
  outline: none;
}

.bm-AuthModal .PhoneInput {
  border: none !important;
  text-decoration: none !important;
  background-color: rgba(255,255,255,0.075);
  color: rgba(255,255,255,0.7);
  border-radius: 10px !important;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  max-height: none !important;
  height: auto !important;
}

.bm-AuthModal__formControl {
  border: none !important;
  text-decoration: none !important;
  background-color: rgba(255,255,255,0.075) !important;
  color: #fff !important;
  border-radius: 10px !important;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  min-height: 50px;
  max-height: none !important;
  width: 100%;
  height: auto !important;
  font-size: 1.5rem !important;
}

.bm-AuthModal .vi__container {
  font-family: gotham-medium;
}

.bm-AuthModal .vi__character {
  border-radius: 0.5rem;
  background-color: rgba(255,255,255,0.075);
  color: #fff;
  border: none;
}