@import '../index.scss';

.bm-CheckoutTicket__discount {
  width: 75%;
}

.bm-CheckoutTicket__tickets {
  width: 100%;
  max-width: 60%;
}

.bm-CheckoutTicket__event--mobile h6 {
  font-size: 1.25rem !important;
}

.bm-CheckoutTicket__accordion {
  background-color: #FFFFFF;
  border-radius: 20px;
  overflow: hidden;
  padding: 2rem;
  width: 75%;
}

.accordion-collapse.show {
  padding-top: 1rem;
}

.accordion-button {
  background: none;
  border: none;
  padding: 0px;
  width: 100%;
}

.accordion-header {
  width: 100%;
}

.bm-CheckoutTicket__accordionItem {
  border-bottom: 1px solid #c7c7c7;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
}

.bm-CheckoutTicket__purchaseOptions {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  border: 0.5px solid #c7c7c7;
  border-radius: 20px;
}

@include media-breakpoint-down(md) {
  .bm-CheckoutTicket__discount {
    width: 100%;
  }

  .bm-CheckoutTicket__tickets {
    max-width: 100%;
  }

  .bm-CheckoutTicket__event--mobile h6 {
    font-size: 1.15rem !important;
  }

  .bm-CheckoutTicket__accordion {
    padding: 1rem;
    width: 100%;
  }
}