@import '../index.scss';

@mixin common-styles {
  font-family: gotham-medium !important;

  .bm-Search__input {
    background-color: transparent;
    font-size: 1.2rem !important;
    // padding-left: 0px !important;
  }

  .bm-Search__formControl.form-control {
    font-size: 1rem;
    font-family: gotham-medium !important;
    border: none !important;
    text-decoration: none !important;
    color: #fff;
    border-radius: 10px !important;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    max-height: none !important;
    height: auto !important;
  }

  .bm-Search__formControl.form-control:focus {
    background-color: transparent !important;
    outline: none !important;
    border: none !important;
    color: #fff;
  }

  .bm-Search__formControl.form-control:active {
    background-color: transparent !important;
    outline: none !important;
    border: none !important;
    color: #fff;
  }

  .bm-Search__searchItem {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
    width: 100%;
  }

  .bm-Search__searchItem:hover {
    background-color: darken($color: $secondaryButton, $amount: 2.5) !important;
  }

  .modal-header {
    border-bottom-width: 0.25px;
    border-bottom-color: rgba(0,0,0,0.25);
    padding-top: 1rem !important;
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .modal-content {
    padding-left: 0 !important;
    padding-right: 0 !important;
    border-radius: 30px;
  }

  .modal-body {
    max-height: 600px;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  @include media-breakpoint-down(md) {
    .modal-content {
      border-radius: 0px;
    }

    .modal-body {
      max-height: none;
    }
  }
}

#bm {
  .bm-Search {
    @include common-styles;
  }
}

.bm-SearchModal.modal {
  @include common-styles
}