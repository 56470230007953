@import '../index.scss';

#bm {
  .bm-Event {
    section {
      background: transparent;
    }

    .bm-Event_wrapper {
      padding: 3rem;
    }

    .bm-Event__imageContainer {
      display: flex;
      flex-direction: column;
      align-items: center;
      flex: 1 1 0%;
      position: sticky;
      z-index: 1;
      // top: 92px;
      align-self: start;
      margin-right: 3rem;
    }

    .bm-Event__rightSection {
      flex: 2 2 0%;
      overflow: hidden;
    }

    .bm-Event__image {
      min-width: 375px !important;
      width: 100%;
      margin-bottom: 2rem;
    }

    .bm-Event__eventStatus_text {
      flex: 1 0;
      margin-right: 3rem;
    }

    .bm-Event__linupArtistsContainer {
      overflow-x: scroll;
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */
    }

    .bm-Event__linupArtistsContainer::-webkit-scrollbar {
      display: none;
    }

    .bm-Event__horizontalContainer {
      overflow-x: scroll;
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */
    }

    .bm-Event__horizontalItemSubtext {
      color: #7e7e7e;
    }

    .bm-Event__userButton:focus {
      outline: none;
    }

    .bm-Event__userButton:active {
      outline: none;
    }

    .bm-Event__buttonFooter {
      display: none;
    }

    .bm-Event__getTicketsButton {
      margin-right: 1rem;
    }

    .bm-Event__downloadButtonsContainer {
      display: flex;
      justify-content: flex-start;
      width: 100%;
    }

    @include media-breakpoint-down(md) {
      .container {
        width: 100% !important;
      }

      .bm-Event__container {
        flex-wrap: wrap;
      }

      .bm-Event__image {
        width: 100%;
        margin-bottom: 2rem;
      }

      .bm-Event__getTicketsButton--authorized {
        width: 100%;
        margin-bottom: 1rem;
        margin-right: 0px;
      }

      .bm-Event__eventStatus {
        justify-content: center;
        text-align: center;
      }

      .bm-Event__eventStatus .btn {
        width: 100% !important;
        max-width: none;
      }

      .bm-Event__eventStatus_text {
        width: 100%;
        flex: none;
        margin-bottom: 1rem;
        margin-right: 0px;
      }

      .bm-Event__button {
        flex: 1 0;
        margin-right: 0 !important;
      }

      .bm-Event__sectionHeader {
        font-size: 1.75rem;
      }

      .bm-Event_title {
        text-align: left;
        margin-bottom: 4rem;
      }
  
      .bm-Event_wrapper {
        padding: 0;
        flex-wrap: wrap;
      }

      .bm-Event__imageContainer {
        display: flex;
        flex: 1 1 0%;
        justify-content: center;
        align-items: center;
        margin-bottom: 2rem;
        margin-right: 0;
        width: 100%;
      }

      .bm-Event__rightSection {
        flex: none;
      }

      .bm-Event__downloadButtonsContainer {
        display: flex;
        justify-content: center;
      }

      .bm-Event__buttonFooter {
        display: block;
        position: fixed;
        bottom: 0;
        left: 0;
        justify-content: center;
        // align-items: center;
        width: 100%;
      }
    }
  }
}