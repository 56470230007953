@import '../index.scss';

@mixin common-styles {
  .bm-CheckoutPayment {
    .bm-CheckoutPayment__leftSection {
      flex: 1 0;
      margin-right: 4rem;
    }

    .bm-CheckoutPayment__rightSection {
      flex: 1 0;
    }

    .bm-CheckoutPayment__orderSummary {
      .bm-MediaObject__imageContainer {
        margin-right: 0px;
      }
    }

    @include media-breakpoint-down(md) {
      .bm-CheckoutPayment__leftSection {
        flex: none;
        margin-right: 0px;
        width: 100%;
      }

      .bm-CheckoutPayment__rightSection {
        flex: none;
        width: 100%;
      }
    }
  }
}

#bm {
  @include common-styles;
}

.modal {
  @include common-styles;
}