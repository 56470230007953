@import '../index.scss';

#bm {
  .bm-navbar {
    height: 75px;
    transition: background-color 200ms ease-in;
    z-index: 1001;
  }

  #toggle .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(255, 255, 255, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  }

    .bm-navbar--fill {
      @include media-breakpoint-up(md) {
        background-color: #fff;
        border-bottom-color: #e2e2e2;
      }
      @include media-breakpoint-down(md) {
        background-color: $bg-dark;
        border-bottom-color: rgba(0,0,0,0.85);
      }
      border-bottom-width: 0.5px;
      border-bottom-style: solid;

      #toggle .navbar-toggler-icon {
        background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(0, 0, 0, 0.6)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
      }
  }

  .bm-navbar__tasteTest {
    display: flex;
    align-items: center;
  }

  #persona-switcher.bm-navbar__persona {
    display: flex;
  }

  @include media-breakpoint-down(md) {
    #persona-switcher.bm-navbar__persona {
      display: none !important;
    }

    .bm-navbar__tasteTest {
      display: none;
    }

    .bm-navbar .bm-Navbar__searchButton--mobile {
      display: flex !important;
    }
  }

  @include media-breakpoint-up(md) {
    .bm-navbar .bm-Navbar__searchButton--mobile {
      display: none !important;
    }
  }

  .bm-navbar__body {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .bm-navbar__logo {
    height: 45px;
    width: auto;
  }

  .bm-navbar__rightNav {
    align-items: center;
  }

  @media (max-width: 991.98px) {
    .bm-navbar__rightNav {
      align-items: flex-start;
    }
  }

  @media (min-width: 991.98px) {
    .bm-navbar__rightNav {
      color: #fff;
      text-decoration: none;
    }
  }

  .bm-navbar__rightNav.bm-navbar__rightNav--fill {
    color: rgb(75, 75, 75);
    // border-bottom-color: rgb(75, 75, 75);
  }

  .bm-navbar__rightNav.bm-navbar__rightNav--fill a.bm-navbar__link.bm-navbar__link--active {
    color: #96f;
  }

  // .bm-navbar__rightNav a.bm-navbar__link.bm-navbar__link--active {

  // }

  @media (max-width: 991.98px) {
    .bm-navbar__rightNav a.bm-navbar__link.bm-navbar__link--active {
      color: #96f;
    }
  }

  .bm-navbar__rightNav.bm-navbar__rightNav--fill a.bm-navbar__link:hover {
    color: #96f;
    // border-bottom-color: rgb(75, 75, 75);
  }

  .bm-navbar__rightNav.bm-navbar__rightNav--fill button.bm-navbar__link:hover {
    color: #96f;
    // border-bottom-color: rgb(75, 75, 75);
  }

  a.bm-navbar__link:hover {
    border-bottom-style: solid;
    border-bottom-width: 4px;
    border-color: #fff;
    margin-top: 4px;
  }

  a.bm-navbar__link:active {
    border-bottom-style: solid;
    border-bottom-width: 4px;
    border-color: #fff;
    margin-top: 4px;
  }

  a.bm-navbar__link.bm-navbar__link--active {
    border-bottom-style: solid;
    border-bottom-width: 4px;
    border-color: #fff;
    margin-top: 4px;
  }

  button.bm-navbar__link:hover {
    border-bottom-style: solid;
    border-bottom-width: 4px;
    border-color: #fff;
    margin-top: 4px;
  }

  button.bm-navbar__link:active {
    border-bottom-style: solid;
    border-bottom-width: 4px;
    border-color: #fff;
    margin-top: 4px;
  }

  button.bm-navbar__link.bm-navbar__link--active {
    border-bottom-style: solid;
    border-bottom-width: 4px;
    border-color: #fff;
    margin-top: 4px;
  }

  .bm-navbar__link {
    font-size: 18px;
  }

  .bm-navbar__rightNav--fill a.bm-navbar__link.bm-navbar__link--active {
    border-bottom: none;
    margin-top: 0;
  }

  .bm-navbar__rightNav--fill a.bm-navbar__link:hover {
    color: #96f;
  }

  .bm-navbar__rightNav--fill a.bm-navbar__link:active {
    color: #96f;
  }

  .bm-navbar__rightNav > :not(:last-child) {
    margin-right: 32px;
  }

  .bm-navbar__link {
    color: inherit;
    text-decoration: none;
  }

  @media (max-width: 991.98px) {
    .offcanvas-collapse {
      position: fixed;
      top: 75px; /* Height of navbar */
      bottom: 0;
      left: 100%;
      width: 100%;
      padding: 1.25rem;
      padding-left: 1.5rem;
      padding-right: 1.5rem;
      text-align: left;
      overflow-y: auto;
      visibility: hidden;
      background-color: #1E1E1E;
      transition-timing-function: ease-in-out;
      transition-duration: 0.2s;
      transition-property: left, visibility;
    }
    .offcanvas-collapse.open {
      left: 0;
      visibility: visible;
    }

    .offcanvas-collapse.open .bm-navbar__link {
      color: #FFF;
    }

    .offcanvas-collapse.open .nav-item {
      color: #FFF;
      margin-bottom: 1.25rem !important;
    }

    .navbar-brand {
      margin-right: 0px;
    }
  }
}
