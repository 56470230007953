@import '../index.scss';


@mixin common-styles {
  .bm-CreateFeedPostDrawerButton__formControl {
    border: none !important;
    text-decoration: none !important;
    background-color: rgba(255,255,255,0.075);
    color: #fff;
    border-radius: 20px !important;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    max-height: none !important;
    height: auto !important;
  }

  .bm-CreateFeedPostDrawerButton__formControl.form-control {
    font-size: 1rem;
    border: none !important;
    text-decoration: none !important;
    background-color: rgba(255,255,255,0.075) !important;
    color: #fff;
    border-radius: 10px !important;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    max-height: none !important;
    height: auto !important;
  }

  .bm-CreateFeedPostDrawerButton__formControl.form-control:focus {
    background-color: rgba(255,255,255,0.075) !important;
    color: #fff;
  }

  .bm-CreateFeedPostDrawerButton_checkbox {
    padding-bottom: 1.5rem;
  }
  
  .bm-CreateFeedPostDrawerButton_checkbox input {
    height: 20px;
    width: 20px;
  }
  
}

#bm {
  .bm-CreateFeedPostDrawerButton {
    @include common-styles;
  }
}

.modal {
  @include common-styles
}