@import '../index.scss';

#bm {
  .bm-Artist {
    section {
      background: transparent;
    }

    .bm-Artist_wrapper {
      padding: 3rem;
    }

    .bm-Artist__imageContainer {
      display: flex;
      flex-direction: column;
      align-items: center;
      flex: 1 1 0%;
      position: sticky;
      z-index: 1;
      // top: 92px;
      align-self: start;
      margin-right: 3rem;
    }

    .bm-Artist__rightSection {
      flex: 2 2 0%;
      overflow: hidden;
    }

    .bm-Artist__followButton {
      width: 150px;
    }

    .bm-Artist__horizontalItemSubtext {
      color: #7e7e7e;
    }

    .bm-Artist__image {
      min-width: 375px !important;
      width: 100%;
      margin-bottom: 2rem;
      border-radius: 50%;
    }

    .bm-Artist__linupArtistsContainer {
      width: 100%;
      overflow-x: scroll;
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */
    }

    .bm-Artist__linupArtistsContainer::-webkit-scrollbar {
      display: none;
    }

    .bm-Artist__buttonFooter {
      display: none;
    }

    .bm-Artist__openExternalButton {
      transition: ease 250ms;
      height: 50px !important;
      width: 50px !important;
      max-height: 50px !important;
      max-width: 50px !important;
      border-radius: 10px !important;
      color: #fff !important;
      display: flex !important;
      justify-content: center !important;
      align-items: center !important;
      text-decoration: none !important;
      background-color: #1d2124;
    }

    .bm-Artist__downloadButtonsContainer {
      display: flex;
      justify-content: flex-start;
      width: 100%;
    }

    @include media-breakpoint-down(md) {
      .container {
        width: 100% !important;
      }

      .bm-Artist__container {
        flex-wrap: wrap;
      }

      .bm-Artist__image {
        min-width: 200px !important;
        width: 25%;
        margin-bottom: 1rem;
      }

      .bm-Artist_title {
        text-align: left;
      }
      
      .bm-Artist__sectionHeader {
        font-size: 1.75rem;
      }
  
      .bm-Artist__followButton {
        width: 100%;
      }
  
      .bm-Artist_wrapper {
        padding: 0;
        flex-wrap: wrap;
      }

      .bm-Artist__imageContainer {
        display: flex;
        flex: 1 1 0%;
        justify-content: center;
        align-items: center;
        margin-bottom: 2rem;
        margin-right: 0;
        width: 100%;
      }

      .bm-Artist__rightSection {
        flex: none;
      }

      .bm-Artist__downloadButtonsContainer {
        display: flex;
        justify-content: center;
      }

      .bm-Artist__buttonFooter {
        display: block;
        position: fixed;
        bottom: 0;
        left: 0;
        justify-content: center;
        // align-items: center;
        width: 100%;
      }
    }
  }
}