@import '../index.scss';

.bm-BuyButton__modal {

  .modal-dialog {
    background-color: transparent;
    border: none !important;
    margin: 0;
    padding: 0;
    width: 100%;
  }

  .modal-content {
    background-color: transparent;
    border: none !important;
    margin: 0;
    padding: 0 !important;
    width: 100%;
    overflow: visible;
    min-width: 100vw;
  }

  .modal-body {
    background-color: transparent;
    border: none !important;
    margin: 0;
    padding: 0;
    width: 100%;
    position: relative;
    min-width: 100vw;
  }

  .modal-dialog-centered.modal-dialog-scrollable {
    flex-direction: row !important;
    justify-content: flex-start;
    max-height: 100vh;
  }

  .bm-BuyButton__header {
    margin-bottom: 3rem;
  }

  .bm-BuyButton__currentUserImage {
    margin-right: 0.5rem;
  }

  @include media-breakpoint-down(md) {
    .bm-BuyButton__header {
      margin-bottom: 1rem !important;
    }

    .bm-BuyButton__stepperHeader {
      margin-bottom: 2rem !important;
    }

    .bm-BuyButton__currentUserImage {
      margin-right: 0px;
    }
  }
}

.bm-BuyButton__promoCodeModal {
  .modal-dialog {
    max-width: 500px;
  }

  .modal-header {
    border-bottom-width: 0.25px;
    border-bottom-color: rgba(0,0,0,0.25);
    padding-top: 1.5rem;
    padding-left: 1.5rem;
    padding-right: 0.5rem;
  }

  .modal-content {
    border-radius: 20px;
    padding-left: 1rem;
    padding-right: 1rem;
  }
  
  .modal-footer {
    border-top-width: 0.25px;
    border-top-color: rgba(0,0,0,0.25);
  }
}