@import '../index.scss';

#bm {

  .bm-FeatureSection_heading--lg {
    font-size: 5.5rem;
  }

  .bm-FeatureSection_heading--md {
    font-size: 3.5rem;
  }

  @include media-breakpoint-down(md) {
    .bm-FeatureSection {
      flex-direction: row-reverse;
    }

    .bm-FeatureSection_heading--lg {
      font-size: 3.5rem;
    }

    .bm-FeatureSection_heading--md {
      font-size: 2.5rem;
    }
    
    .bm-FeatureSection_heading, .bm-FeatureSection_subheading {
      text-align: center;
    }

    .bm-FeatureSection__bannerImage {
      margin-left: 0 !important;
      margin-right: 0 !important;
      margin-bottom: 2rem;
      width: 100%;
    }

    .bm-FeatureSection__bannerImage.bm-FeatureSection__bannerImage--large {
      margin-left: 0 !important;
      margin-right: 0 !important;
      margin-bottom: 2rem;
    }

    .bm-FeatureSection__bannerDescription {
      text-align: center;
      justify-content: center;
      width: 100%;
    }

    .bm-FeatureSection_bannerDescription--center-mobile {
      justify-content: center;
    }
  }

  @include media-breakpoint-up(lg) {
    .bm-FeatureSection__bannerImage {
      flex: 1;
    }

    .flex-row-reverse .bm-FeatureSection__bannerImage {
      margin-right: 0rem;
    }

    .bm-FeatureSection__bannerImage {
      margin-right: 4.5rem;
    }

    .bm-FeatureSection__bannerImage--reverse {
      margin-right: 0rem !important;
    }

    .flex-row-reverse .bm-FeatureSection__bannerDescription {
      margin-right: 4rem;
    }

    .bm-FeatureSection__bannerDescription {
      flex: 1;
      margin-right: 0rem;
    }
  }
}