@use 'node_modules/aos/src/sass/aos.scss';
@use './assets/stylesheets/bootstrap/scss/bootstrap.scss';
@import './assets/stylesheets/bootstrap/scss/variables';
@import './assets/stylesheets/bootstrap/scss/mixins/breakpoints';
@font-face {
  font-family: gotham-medium;
  src: url('./assets/fonts/hinted-Sk-Modernist-Bold.ttf') format('truetype'),
  ;
    // url('./assets/fonts/Gotham-Medium.woff') format('woff'), url('./assets/fonts/Gotham-Medium.ttf') format('truetype'),
    // url('./assets/fonts/Gotham-Medium.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

$primary: #9966ff;
$light-primary: rgba(153, 102, 255, 0.5);
$bg-dark: #060710;
$secondary: #fff;
$enable-responsive-font-sizes: true;
$secondary-text: #7e7e7e;
$secondaryButton: #1E1E1E;

#bm {
  max-width: 100%;
}

.bg-dark {
  background-color: $bg-dark !important;
}

.bg-secondaryButton {
  background-color: #1E1E1E;
}

.bg-overlay {
  background-color: rgba(255,255,255,0.075);
}

.bg-light-primary {
  background-color: $light-primary;
}

.aspect-square {
  aspect-ratio: 1 !important;
}

.bm-Checkbox {
  background-color: $primary !important;
}

.bm-Checkbox::before {
  background-color: $primary !important;
}

.bm-Checkbox::after {
  background-color: $primary !important;
}

.modal {
  z-index: 10000 !important;
}

.modal-content {
  overflow-x: hidden;
}

body.modal-open {
  overflow: visible;
}

.MuiDialog-root {
  z-index: 10000 !important;
}

.bm-Text--strokeText {
  color: #FFFFFF;
  -webkit-text-fill-color: rgba(0,0,0,0); /* Will override color (regardless of order) */
  -webkit-text-stroke-width: 1.1px;
  -webkit-text-stroke-color: #FFFFFF;
}

.bm-Text--secondaryText {
  color: $secondary-text;
}

.bm-Text--strikeText {
  text-decoration: line-through;
}

@include media-breakpoint-down(md) {
  .bm-Text--strokeText {
    -webkit-text-stroke-width: 0.5px;
  }

  .bm-Modal--mobile .modal-dialog {
    display: block;
    margin: 0 !important;
  }
  
  .bm-Modal--mobile .modal-content {
    height: 100vh;
    width: 100vw;
  }
}

.bm-Text--strokeText.bm-Text--strokeText-dark {
  -webkit-text-stroke-color: #343a40;
}

/* Tabs*/
#bm .nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link {
  background-color: transparent !important;
  border: none;
  background-color: transparent;
  color: #4b4b4b;
}

#bm .nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  background-color: transparent !important;
  border: none;
  border-bottom: 5px solid !important;
}

#bm .nav-tabs {
  border-bottom: none;
  margin-bottom: 1.5rem;
}

#bm .nav-tabs .nav-link.active {
  color: #96f;
}

#bm .tabs-white.nav-tabs .nav-link {
  color: #fff !important;
  margin-right: 1rem;
}

#bm .tabs-white.nav-tabs .nav-link.active {
  color: #fff !important;
}

#bm .tabs-white.nav-tabs .nav-link:hover:not(.active) {
  border: none;
  border-bottom: 5px solid #f7f7f798 !important;
}

html {
  font-size: 0.9rem;
}

@include media-breakpoint-up(xs) {
  html {
    font-size: 0.9rem;
  }
}

@include media-breakpoint-up(sm) {
  html {
    font-size: 1rem;
  }
}

@include media-breakpoint-up(md) {
  html {
    font-size: 1rem;
  }
}

@include media-breakpoint-up(lg) {
  html {
    font-size: 1rem;
  }
}

body,
html {
  margin: 0;
  font-family: gotham-medium, Fallback, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  width: 100%;
  background-color: #f7f7f7;
  min-height: 100vh;
}

h1 {
  font-size: 3.5rem;
}

@include media-breakpoint-down(xs) {
  h1 {
    font-size: 2.5rem;
  }
}

@include media-breakpoint-down(sm) {
  h1 {
    font-size: 2rem;
  }
}

h3 {
  font-size: 1.5rem;
}

@include media-breakpoint-down(xs) {
  h3 {
    font-size: 1.1rem;
  }
}

@include media-breakpoint-down(sm) {
  h3 {
    font-size: 1.3rem;
  }
}

h1,
h2 {
  // font-family: 'gotham-bold';
  font-weight: 600;
}

#root {
  height: 100%;
  width: 100%;
}

.btn-secondary {
  border: 0.5px solid #e2e2e2;
  color: inherit;
}

@keyframes eventpagebg {
  0%{background-position:0% 50%}
  24%{background-position:60% 20%}
  50%{background-position:100% 50%}
  64%{background-position:0% 50%}
  82%{background-position:100% 50%}
  100%{background-position:0% 50%}
}

.bg-animated {
  animation: eventpagebg 34s infinite ease;
}