@import '../index.scss';

#bm {
  .bm-Collection {}

  .bm-Collection__listCollection {}

  .bm-Collection .bm-Collection__event {}

  .bm-Collection__horizontalItemSubtext {
    color: #7e7e7e;
  }

  @include media-breakpoint-down(md) {
    .bm-Collection .bm-Collection__community {
      width: 275px !important;
    }

    .bm-Collection .bm-Collection__community img {
      border-radius: 15px !important;
    }

    .bm-Collection .bm-Collection__event {
      width: 150px !important;
    }

    .bm-Collection .bm-Collection__event img {
      border-radius: 5px !important;
    }

    .bm-Collection__heading {
      font-size: 1.75rem;
    }

    .bm-Collection__subheading {
      font-size: 1.25rem;
    }
  }
}