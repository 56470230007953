@import '../index.scss';

.bm-CheckoutComplete__ticketImage {
  width: 20%;
  min-width: 22rem;
  max-width: 100%;
  object-fit: cover;
  border-radius: 20px;
}

@include media-breakpoint-down(md) {
  .bm-CheckoutComplete__ticketImage {
    width: 100%;
    aspect-ratio: 1;
  }
}