@import '../index.scss';

#bm {
  .bm-FlatList {}

  .bm-FlatList::-webkit-scrollbar {
    display: none;
  }

  .bm-FlatList.bm-FlatList--grid {
    display: grid !important;
    grid-template-columns: repeat(3, 1fr) !important;
    gap: 0.25rem;
  }

  .bm-FlatList.bm-FlatList--grid--4-columns {
    grid-template-columns: repeat(4, 1fr) !important;
  }

  .bm-FlatList.bm-FlatList--grid--3-columns {
    grid-template-columns: repeat(3, 1fr) !important;
  }

  .bm-FlatList.bm-FlatList--grid--2-columns {
    grid-template-columns: repeat(2, 1fr) !important;
  }

  .bm-FlatList.bm-FlatList--grid--1-columns {
    grid-template-columns: repeat(1, 1fr) !important;
  }

  @include media-breakpoint-down(md) {
    .bm-FlatList.bm-FlatList--grid.bm-FlatList--grid--noMobile {
      display: block !important;
    }

    .bm-FlatList.bm-FlatList--grid {
      grid-template-columns: repeat(2, 1fr) !important;
    }
  }
}