@import '../index.scss';

#bm {
  .bm-ForFans__section {
    background-color: $bg-dark;
    min-height: 85vh;
  }

  .bm-ForFans_section1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    background-color: $bg-dark;
    margin-top: -75px;
    padding-top: 18vh;
    min-height: 100vh;
  }

  .bm-ForFans_section2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: $bg-dark;
    min-height: 65vh;
  }

  .bm-ForFans_section3 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 75vh;
  }

  .bm-ForFans_section4 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: $bg-dark;
    min-height: 87.5vh;
  }

  .bm-ForFans_section5 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 75vh;
  }

  .bm-ForFans_section6 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: $bg-dark;
    min-height: 50vh;
  }

  .bm-ForFans_section7 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: $bg-dark;
    min-height: 45vh;
  }
  
  .bm-ForFans__statCard {
    background-color: $primary;
    border-radius: 20px;
    position: absolute;
    bottom: 40;
    left: -40;
    overflow: hidden;
    box-shadow: 5px 3px 3px rgba(0,0,0,0.25);
  }

  .bm-Event__linupArtistsContainer {
    overflow-x: scroll;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }

  .bm-Event__linupArtistsContainer::-webkit-scrollbar {
    display: none;
  }

  @include media-breakpoint-up(lg) {
    .bm-ForFans__featuredEvents--lg {
      display: block !important;
    }
  }

  @include media-breakpoint-down(md) {
    .bm-ForFans__featuredEvents--sm {
      display: block !important;
    }

    .bm-ForFans__statCard {
      background-color: $primary;
      border-top-left-radius: 0px !important;
      border-bottom-left-radius: 20px !important;
      border-bottom-right-radius: 0px !important;
      bottom: 0 !important;
      left: 0 !important;
    }
  }

  .bm-ForFans__benefits .bm-ForFans__benefit:not(:last-child) {
    margin-right: 1.25rem;
  }

  .bm-ForFans_pressContainer {
    height: 100px;
    width: 30%;
    border-radius: 20px;
  }

  .bm-ForFans_heading {
    font-size: 5.5rem;
  }

  .bm-ForFans_eventsHeader h1 {
    font-size: 6.5rem !important;
  }

  .bm-ForFans__whatsPoppinButton {
    margin-right: 1rem;
  }

  .bm-ForFans_investors::-webkit-scrollbar {
    display: none;
  }

  .bm-ForFans_investors {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    overflow-x: scroll;
    overflow-y: hidden;
    height: 125px;
  }

  .bm-ForFans_investorLogo {
    opacity: 1;
    height: auto;
    width: 8rem;
    aspect-ratio: 1;
    margin-left: 3rem;
    margin-right: 3rem;

    svg {
      width: 100%;
      aspect-ratio: 1;
    }
  }

  .bm-ForFans_highlight {
    position: relative;
    height: 18.75rem;
    width: 15.625rem;
  }

  @include media-breakpoint-down(md) {
    .bm-ForFans_heading {
      text-align: center;
      font-size: 3.75rem;
    }

    h1 {
      font-size: 2.75rem;
    }

    .bm-ForFans__primaryCTA {
      width: 100%;
    }

    .bm-ForFans_pressContainer {
      width: 100%;
      margin-bottom: 2rem;
    }

    .bm-ForFans_subheading {
      text-align: center;
    }

    .bm-ForFans__bannerImage {
      margin-left: 0 !important;
      margin-right: 0 !important;
      margin-bottom: 2rem;
      width: 100%;
    }

    .bm-ForFans__bannerImage.bm-ForFans__bannerImage--large {
      margin-left: 0 !important;
      margin-right: 0 !important;
      margin-bottom: 2rem;
    }

    .bm-ForFans__bannerDescription {
      text-align: center;
      justify-content: center;
      width: 100%;
    }

    .bm-ForFans_bannerDescription--center-mobile {
      justify-content: center;
    }

    .bm-ForFans__whatsPoppinButton {
      margin-right: 0px;
    }

    .bm-ForFans_investorLogo {
      height: 5rem;
      width: auto;
      aspect-ratio: 1;
      margin-top: 0.75rem;
      margin-bottom: 0.75rem;
      aspect-ratio: 1;

      svg {
        width: 100%;
        aspect-ratio: 1;
      }
      // margin-left: 2rem;
      // margin-right: 2rem;
    }

    .bm-ForFans_highlight {
      position: relative;
      height: 11rem;
      width: 9.16rem;
      font-size: 0.75rem !important;
    }
  }

  @include media-breakpoint-up(lg) {
    .bm-ForFans__bannerImage {
      flex: 2;
      margin-right: 4.5rem;
    }

    .bm-ForFans__bannerImage.bm-ForFans__bannerImage--large {
      flex: 3;
      margin-right: 4.5rem;
    }

    .bm-ForFans__bannerImage--reverse {
      margin-right: 0rem !important;
    }

    .bm-ForFans__bannerDescription {
      flex: 3;
      margin-right: 6rem;
    }
  }
}