@import '../index.scss';

#bm {
  .bm-ForOrganizers {
    background-color: $bg-dark;
    margin-top: -75px;
    padding-top: 18vh;
  }

  .bm-ForOrganizers__heroSection {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: $bg-dark;
  }

  .bm-ForOrganizers__section {
    background-color: $bg-dark;
    min-height: 85vh;
  }

  .bm-ForOrganizers_section2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: $bg-dark;
    min-height: 6vh;
  }

  .bm-ForOrganizers_section3 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 75vh;
  }

  .bm-ForOrganizers_section4 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: $bg-dark;
    min-height: 87.5vh;
  }

  .bm-ForOrganizers_section5 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 75vh;
  }

  .bm-ForOrganizers_section6 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: $bg-dark;
    margin-bottom: 7.5rem;
  }

  .bm-ForOrganizers_section7 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: $bg-dark;
    min-height: 45vh;
  }

  .bm-ForOrganizers .bm-PostPreview_image {
    aspect-ratio: 4/2.5 !important;
  }

  .bm-Event__linupArtistsContainer {
    overflow-x: scroll;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }

  .bm-Event__linupArtistsContainer::-webkit-scrollbar {
    display: none;
  }

  @include media-breakpoint-up(lg) {
    .bm-ForOrganizers__featuredEvents--lg {
      display: block !important;
    }
  }

  @include media-breakpoint-down(md) {
    .bm-ForOrganizers__featuredEvents--sm {
      display: block !important;
    }
  }

  .bm-ForOrganizers__benefits .bm-ForOrganizers__benefit:not(:last-child) {
    margin-right: 1.25rem;
  }

  .bm-ForOrganizers_pressContainer {
    height: 100px;
    width: 30%;
    border-radius: 20px;
  }

  .bm-ForOrganizers_heading {
    font-size: 5.5rem;
  }

  .bm-ForOrganizers_eventsHeader h1 {
    font-size: 6.5rem !important;
  }

  .bm-ForOrganizers__whatsPoppinButton {
    margin-right: 1rem;
  }

  .bm-ForOrganizers_investors::-webkit-scrollbar {
    display: none;
  }

  .bm-ForOrganizers_investors {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    overflow-x: scroll;
    overflow-y: hidden;
    height: 125px;
  }

  .bm-ForOrganizers_investorLogo {
    opacity: 1;
    height: auto;
    width: 8rem;
    aspect-ratio: 1;
    margin-left: 3rem;
    margin-right: 3rem;

    svg {
      width: 100%;
      aspect-ratio: 1;
    }
  }

  .bm-ForOrganizers_highlight {
    position: relative;
    height: 18.75rem;
    width: 15.625rem;
  }

  @include media-breakpoint-down(md) {
    .bm-ForOrganizers_heading {
      text-align: center;
      font-size: 3.75rem;
    }

    .bm-ForOrganizers__section {
      margin-bottom: 10rem;
    }

    .bm-ForOrganizers_investorLogo {
      margin-left: 2rem;
      margin-right: 2rem;
    }

    h1 {
      font-size: 2.75rem;
    }

    .bm-ForOrganizers__primaryCTA {
      width: 100%;
    }

    .bm-ForOrganizers_pressContainer {
      width: 100%;
      margin-bottom: 2rem;
    }

    .bm-ForOrganizers_subheading {
      text-align: center;
    }

    .bm-ForOrganizers__bannerImage {
      margin-left: 0 !important;
      margin-right: 0 !important;
      margin-bottom: 2rem;
      width: 100%;
    }

    .bm-ForOrganizers__bannerImage.bm-ForOrganizers__bannerImage--large {
      margin-left: 0 !important;
      margin-right: 0 !important;
      margin-bottom: 2rem;
    }

    .bm-ForOrganizers__bannerDescription {
      text-align: center;
      justify-content: center;
      width: 100%;
    }

    .bm-ForOrganizers_bannerDescription--center-mobile {
      justify-content: center;
    }

    .bm-ForOrganizers__whatsPoppinButton {
      margin-right: 0px;
    }

    .bm-ForOrganizers_investorLogo {
      height: 5rem;
      width: auto;
      aspect-ratio: 1;
      margin-top: 0.75rem;
      margin-bottom: 0.75rem;
      aspect-ratio: 1;

      svg {
        width: 100%;
        aspect-ratio: 1;
      }
      // margin-left: 2rem;
      // margin-right: 2rem;
    }

    .bm-ForOrganizers_highlight {
      position: relative;
      height: 11rem;
      width: 9.16rem;
      font-size: 0.75rem !important;
    }
  }

  @include media-breakpoint-up(lg) {
    .bm-ForOrganizers__bannerImage {
      flex: 2;
      margin-right: 4.5rem;
    }

    .bm-ForOrganizers__bannerImage.bm-ForOrganizers__bannerImage--large {
      flex: 3;
      margin-right: 4.5rem;
    }

    .bm-ForOrganizers__bannerImage--reverse {
      margin-right: 0rem !important;
    }

    .bm-ForOrganizers__bannerDescription {
      flex: 3;
      margin-right: 6rem;
    }
  }
}