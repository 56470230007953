@import '../index.scss';

#bm {
  .bm-Events {}

  .bm-Events__section1 {
    margin-top: -75px;
    padding-top: 17vh !important;
  }

  .bm-Events__featuredEvent_image {
    width: 425px;
  }
  
  .bm-Events__featuredEvents::-webkit-scrollbar {
    display: none;
  }

  .bm-Events__featuredEvents {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    overflow-x: scroll;
    overflow-y: hidden;
  }

  .bm-Events__featuredEvents .bm-Events__featuredEvents__subtext {
    color: #7e7e7e;
  }

  .bm-Event__eventsFeed {
    margin-bottom: 4rem;
  }

  .bm-Event__eventsFeed .bm-MediaObject_text,.bm-MediaObject_subtext {
    font-size: 24px;
  }

  .bm-Events__getTicketsBtn {
    height: 65px;
    max-height: 65px;
    min-width: 11rem;
    margin-right: 1rem;
    border-radius: 50px;
  }

  .bm-Events_sectionHeader {
    font-size: 4rem;
    line-height: 4.5rem;
  }
  
  .bm-Events__findYourMatchBtn {
    height: 65px;
    max-height: 65px;
    min-width: 11rem;
    border-radius: 50px;
  }

  @include media-breakpoint-down(md) {
    .bm-Events__featuredEvent_image {
      width: 300px;
    }

    .bm-Event__eventsFeed {
      flex-wrap: wrap;
    }

    .bm-Event__buttonGroup {
      width: 100%;
    }

    .bm-Events__getTicketsBtn {
      flex: 1 0 0;
      min-width: 0;
    }

    .bm-Events_sectionHeader {
      font-size: 3.5rem;
      margin-bottom: 1rem;
    }

    .bm-Events__findYourMatchBtn {
      flex: 1 0 0;
      min-width: 0;
    }
  }
}