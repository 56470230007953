@import '../index.scss';


.bm-DrawerButton__modal {
  .modal-dialog {
    min-width: 600px;
  }

  .modal-header {
    border-bottom-width: 0.25px;
    border-bottom-color: rgba(0,0,0,0.25);
    padding-top: 1.5rem;
    padding-left: 1.5rem;
    padding-right: 0.5rem;
  }

  .modal-content {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .modal-footer {
    border-top-width: 0.25px;
    border-top-color: rgba(0,0,0,0.25);
    padding-bottom: 1.5rem;
  }
}

@include media-breakpoint-up(md) {
  .bm-DrawerButton__modal .modal-dialog {
    float: right !important;
    margin: 0px;
    height: 100vh;
    min-width: 850px !important;
  }

  .bm-DrawerButton__modal .bm-VerticalNavbar__link_title {
    color: white;
  }
}

@include media-breakpoint-down(md) {
  .bm-DrawerButton__modal .modal-dialog {
    margin: 0px;
    height: 100vh;
    min-width: 600px !important;
  }
}