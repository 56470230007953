@import '../index.scss';

#bm {
  .btn {
    max-height: 45px;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 8px;
    padding-bottom: 8px;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }

  @include media-breakpoint-up(lg) {
    .btn {
      max-width: 350px;
    }
  }

  .btn-secondary {
    border-width: 0.1rem;
  }
}
