@import '../index.scss';

#bm {
  .bm-Footer {
    background-color: $bg-dark;
    width: 100%;
    height: auto;
  }

  .bm-Footer__container {
    min-width: 60%;
    margin-bottom: 1rem;
  }

  @include media-breakpoint-down(sm) {
    .bm-Footer {
      align-items: center !important;
    }

    .bm-Footer__container {
      justify-content: center !important;
      margin-bottom: 3rem;
    }

    .bm-Footer_socials {
      margin-bottom: 3rem;
    }
  }

  @include media-breakpoint-up(md) {
    .bm-Footer__container {
      justify-content: space-between !important;
    }
  }

  .bm-Footer__social_link {
    color: #fff;
    transition: ease 250ms;
  }

  .bm-navbar__rightNav .nav-item .bm-Footer__social_link i {
    color: #FFFFFF;
  }

  .bm-navbar__rightNav--fill .nav-item .bm-Footer__social_link i {
    color: rgb(75, 75, 75);
  }

  .bm-Footer__social_link:hover {
    color: #96f;
  }

  .bm-Footer__social_link:focus {
    color: #96f;
  }
}
