@import '../index.scss';

.modal {
  .modal-dialog {
    min-width: 600px;
  }

  .modal-header {
    border-bottom-width: 0.25px ;
    border-bottom-color: rgba(0,0,0,0.25);
    padding-top: 1.5rem;
    padding-left: 1.5rem;
    padding-right: 0.5rem;
  }

  .modal-content {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .modal-footer {
    border-top-width: 0.25px;
    border-top-color: rgba(0,0,0,0.25);
    padding-bottom: 1.5rem;
  }
}